import revive_payload_client_4sVQNw7RlN from "/data/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_KgADcZ0jPj from "/data/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/data/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import supabase_client_2ruDq4uEtU from "/data/node_modules/@nuxtjs/supabase/dist/runtime/plugins/supabase.client.js";
import payload_client_yVLowv6hDl from "/data/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "/data/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "/data/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "/data/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "/data/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/data/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "/data/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import performance_client_OXWUsvTdFu from "/data/node_modules/nuxt-bugsnag/dist/runtime/client/performance.client.js";
import plugin_adVF2uRka6 from "/data/node_modules/nuxt-bugsnag/dist/runtime/client/plugin.js";
import plugin_tbFNToZNim from "/data/node_modules/@zadigetvoltaire/nuxt-gtm/dist/runtime/plugin.mjs";
import slideovers_X7whXyrIWR from "/data/node_modules/@nuxt/ui/dist/runtime/plugins/slideovers.js";
import modals_oqljskDzoR from "/data/node_modules/@nuxt/ui/dist/runtime/plugins/modals.js";
import colors_ETtiVmZNmO from "/data/node_modules/@nuxt/ui/dist/runtime/plugins/colors.js";
import plugin_client_OVoKJro5pc from "/data/node_modules/@nuxtjs/color-mode/dist/runtime/plugin.client.js";
import plugin_WLsn00x1qh from "/data/node_modules/@nuxt/icon/dist/runtime/plugin.js";
import components_client_ZB1W0EeH0E from "/data/node_modules/nuxt-swiper/dist/runtime/plugins/components.client.js";
import auth_redirect_bWtzICY6fh from "/data/node_modules/@nuxtjs/supabase/dist/runtime/plugins/auth-redirect.js";
import plugin_AUP22rrBAc from "/data/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import datepicker_QdRP4jKyEy from "/data/plugins/datepicker.js";
import gtm_client_OzmBuHLRIb from "/data/plugins/gtm.client.ts";
import helpers_mgWmzbOuLB from "/data/plugins/helpers.ts";
import toast_client_9z8IIRO0Dx from "/data/plugins/toast.client.ts";
import wow_client_utB3fCnsxi from "/data/plugins/wow.client.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  supabase_client_2ruDq4uEtU,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  performance_client_OXWUsvTdFu,
  plugin_adVF2uRka6,
  plugin_tbFNToZNim,
  slideovers_X7whXyrIWR,
  modals_oqljskDzoR,
  colors_ETtiVmZNmO,
  plugin_client_OVoKJro5pc,
  plugin_WLsn00x1qh,
  components_client_ZB1W0EeH0E,
  auth_redirect_bWtzICY6fh,
  plugin_AUP22rrBAc,
  datepicker_QdRP4jKyEy,
  gtm_client_OzmBuHLRIb,
  helpers_mgWmzbOuLB,
  toast_client_9z8IIRO0Dx,
  wow_client_utB3fCnsxi
]