import { default as _91_46_46_46page_93qb8KF73uSTMeta } from "/data/pages/[...page].vue?macro=true";
import { default as account_45login49hUPwfJJ4Meta } from "/data/pages/account-login.vue?macro=true";
import { default as _91slug_93f4BQgpNokCMeta } from "/data/pages/account/[slug].vue?macro=true";
import { default as _91id_93VpEwTTGrBBMeta } from "/data/pages/account/holidays/[id].vue?macro=true";
import { default as _91id_93xpRwyTyY7jMeta } from "/data/pages/account/holidays/payments/[id].vue?macro=true";
import { default as indexX6Tsj9kTOCMeta } from "/data/pages/account/index.vue?macro=true";
import { default as confirmation6PplCC2ksGMeta } from "/data/pages/booking/confirmation.vue?macro=true";
import { default as processingT355wAFzD9Meta } from "/data/pages/booking/processing.vue?macro=true";
import { default as _91id_934T0G972pdtMeta } from "/data/pages/booking/step/[id].vue?macro=true";
import { default as summaryS3JUlD3SduMeta } from "/data/pages/booking/step/summary.vue?macro=true";
import { default as _91_46_46_46post_93fRv2gOZAw5Meta } from "/data/pages/events/[...post].vue?macro=true";
import { default as indexTxEJcNrZRQMeta } from "/data/pages/events/index.vue?macro=true";
import { default as _91number_930Zvg8TvtnxMeta } from "/data/pages/events/page/[number].vue?macro=true";
import { default as _91_46_46_46page_93zNYeYPo2XHMeta } from "/data/pages/homes-for-sale/[...page].vue?macro=true";
import { default as loginTzDppsKZcdMeta } from "/data/pages/login.vue?macro=true";
import { default as _91_46_46_46post_93QEWV0bL85BMeta } from "/data/pages/news/[...post].vue?macro=true";
import { default as _91slug_93RqEXc8xHedMeta } from "/data/pages/news/category/[slug].vue?macro=true";
import { default as indexgL9dMC5lenMeta } from "/data/pages/news/index.vue?macro=true";
import { default as _91number_93GgAGeGKALeMeta } from "/data/pages/news/page/[number].vue?macro=true";
import { default as otpJYjBPzeXZTMeta } from "/data/pages/otp.vue?macro=true";
import { default as password_45resetYFOIf8k4ErMeta } from "/data/pages/password-reset.vue?macro=true";
import { default as registerscOR9dqsdwMeta } from "/data/pages/register.vue?macro=true";
import { default as search_45calendarFAYLpMIiOnMeta } from "/data/pages/search-calendar.vue?macro=true";
import { default as resultsHycuFbrwttMeta } from "/data/pages/search/results.vue?macro=true";
import { default as style_45guide1nECe9QcxBMeta } from "/data/pages/style-guide.vue?macro=true";
import { default as update_45passwordhEpuxrIlo2Meta } from "/data/pages/update-password.vue?macro=true";
export default [
  {
    name: "page",
    path: "/:page(.*)*",
    component: () => import("/data/pages/[...page].vue")
  },
  {
    name: "account-login",
    path: "/account-login",
    component: () => import("/data/pages/account-login.vue")
  },
  {
    name: "account-slug",
    path: "/account/:slug()",
    meta: _91slug_93f4BQgpNokCMeta || {},
    component: () => import("/data/pages/account/[slug].vue")
  },
  {
    name: "account-holidays-id",
    path: "/account/holidays/:id()",
    meta: _91id_93VpEwTTGrBBMeta || {},
    component: () => import("/data/pages/account/holidays/[id].vue")
  },
  {
    name: "account-holidays-payments-id",
    path: "/account/holidays/payments/:id()",
    meta: _91id_93xpRwyTyY7jMeta || {},
    component: () => import("/data/pages/account/holidays/payments/[id].vue")
  },
  {
    name: "account",
    path: "/account",
    meta: indexX6Tsj9kTOCMeta || {},
    component: () => import("/data/pages/account/index.vue")
  },
  {
    name: "booking-confirmation",
    path: "/booking/confirmation",
    component: () => import("/data/pages/booking/confirmation.vue")
  },
  {
    name: "booking-processing",
    path: "/booking/processing",
    component: () => import("/data/pages/booking/processing.vue")
  },
  {
    name: "booking-step-id",
    path: "/booking/step/:id()",
    component: () => import("/data/pages/booking/step/[id].vue")
  },
  {
    name: "booking-step-summary",
    path: "/booking/step/summary",
    component: () => import("/data/pages/booking/step/summary.vue")
  },
  {
    name: "events-post",
    path: "/events/:post(.*)*",
    component: () => import("/data/pages/events/[...post].vue")
  },
  {
    name: "events",
    path: "/events",
    component: () => import("/data/pages/events/index.vue")
  },
  {
    name: "events-page-number",
    path: "/events/page/:number()",
    component: () => import("/data/pages/events/page/[number].vue")
  },
  {
    name: "homes-for-sale-page",
    path: "/homes-for-sale/:page(.*)*",
    component: () => import("/data/pages/homes-for-sale/[...page].vue")
  },
  {
    name: "login",
    path: "/login",
    component: () => import("/data/pages/login.vue")
  },
  {
    name: "news-post",
    path: "/news/:post(.*)*",
    component: () => import("/data/pages/news/[...post].vue")
  },
  {
    name: "news-category-slug",
    path: "/news/category/:slug()",
    component: () => import("/data/pages/news/category/[slug].vue")
  },
  {
    name: "news",
    path: "/news",
    component: () => import("/data/pages/news/index.vue")
  },
  {
    name: "news-page-number",
    path: "/news/page/:number()",
    component: () => import("/data/pages/news/page/[number].vue")
  },
  {
    name: "otp",
    path: "/otp",
    component: () => import("/data/pages/otp.vue")
  },
  {
    name: "password-reset",
    path: "/password-reset",
    component: () => import("/data/pages/password-reset.vue")
  },
  {
    name: "register",
    path: "/register",
    component: () => import("/data/pages/register.vue")
  },
  {
    name: "search-calendar",
    path: "/search-calendar",
    component: () => import("/data/pages/search-calendar.vue")
  },
  {
    name: "search-results",
    path: "/search/results",
    component: () => import("/data/pages/search/results.vue")
  },
  {
    name: "style-guide",
    path: "/style-guide",
    component: () => import("/data/pages/style-guide.vue")
  },
  {
    name: "update-password",
    path: "/update-password",
    component: () => import("/data/pages/update-password.vue")
  }
]