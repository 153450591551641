<template>
    <div class="o-mobile-menu js-mobile-menu" :class="[{ 'is-active': state == true }]">
        <div v-if="mobileMenuData.featured_links" class="o-mobile-menu__featured-links">
            <NuxtLink
                v-for="(link, index) in mobileMenuData.featured_links"
                :key="index"
                class="o-mobile-menu__featured-link"
                :to="link.link.url"
                :target="link.link.target">
                <span v-html="link.link.title"></span>
            </NuxtLink>
        </div>

        <nav class="o-mobile-menu__nav js-mobile-menu-nav">
            <ul>
                <li
                    v-for="(link, index) in primary_navigation"
                    :key="index"
                    class="o-mobile-menu__parent-link-wrapper js-mobile-menu__parent-link-wrapper">
                    <NuxtLink
                        v-if="!link.child_link_sections"
                        class="o-mobile-menu__parent-link"
                        :to="link.parent_link.url">
                        <span v-html="link.parent_link.title"></span>
                    </NuxtLink>

                    <div v-else class="o-mobile-menu__section-toggle-container">
                        <div
                            v-if="!link.parent_link.url || link.parent_link.url === '#'"
                            class="o-mobile-menu__parent-link">
                            <span v-html="link.parent_link.title"></span>
                        </div>

                        <NuxtLink v-else class="o-mobile-menu__parent-link" :to="link.parent_link.url">
                            <span v-html="link.parent_link.title"></span>
                        </NuxtLink>

                        <button
                            class="o-mobile-menu__section-toggle js-mobile-menu__section-toggle"
                            type="button"
                            :aria-label="`Toggle ${link.parent_link.title} section`"
                            @click="toggleChildLinks($event, true)"></button>
                    </div>

                    <ul v-if="link.child_link_sections" class="o-mobile-menu__child-links js-mobile-menu__child-links">
                        <li
                            v-for="(link, index) in link.child_link_sections"
                            :key="index"
                            class="o-mobile-menu__child-link-wrapper js-mobile-menu__child-link-wrapper">
                            <NuxtLink
                                v-if="!link.child_links"
                                class="o-mobile-menu__child-link"
                                :to="link.section_link.url">
                                <span v-html="link.section_title"></span>
                            </NuxtLink>

                            <div v-else class="o-mobile-menu__section-toggle-container">
                                <div v-if="!link.section_link" class="o-mobile-menu__child-link">
                                    <span v-html="link.section_title"></span>
                                </div>

                                <NuxtLink v-else class="o-mobile-menu__child-link" :to="link.section_link.url">
                                    <span v-html="link.section_title"></span>
                                </NuxtLink>

                                <button
                                    class="o-mobile-menu__section-toggle js-mobile-menu__section-toggle"
                                    type="button"
                                    :aria-label="`Toggle ${link.section_link} section`"
                                    @click="toggleChildLinks($event)"></button>
                            </div>

                            <ul v-if="link.child_links" class="o-mobile-menu__child-links js-mobile-menu__child-links">
                                <li
                                    v-for="(link, index) in link.child_links"
                                    :key="index"
                                    class="o-mobile-menu__child-link-wrapper js-mobile-menu__child-link-wrapper">
                                    <NuxtLink
                                        v-if="!link.child_links"
                                        class="o-mobile-menu__child-link"
                                        :to="link.child_link.url">
                                        <span v-html="link.child_link.title"></span>
                                    </NuxtLink>

                                    <div v-else class="o-mobile-menu__section-toggle-container">
                                        <NuxtLink class="o-mobile-menu__child-link" :to="link.child_link.url">
                                            <span v-html="link.child_link.title"></span>
                                        </NuxtLink>

                                        <button
                                            class="o-mobile-menu__section-toggle js-mobile-menu__section-toggle"
                                            type="button"
                                            :aria-label="`Toggle ${link.child_link.title} section`"
                                            @click="toggleChildLinks($event)"></button>
                                    </div>

                                    <ul
                                        v-if="link.child_links"
                                        class="o-mobile-menu__child-links js-mobile-menu__child-links">
                                        <li
                                            v-for="(link, index) in link.child_links"
                                            :key="index"
                                            class="o-mobile-menu__child-link-wrapper js-mobile-menu__child-link-wrapper">
                                            <NuxtLink
                                                v-if="!link.child_links"
                                                class="o-mobile-menu__child-link"
                                                :to="link.child_link.url">
                                                <span v-html="link.child_link.title"></span>
                                            </NuxtLink>
                                        </li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                    </ul>
                </li>

                <li
                    v-if="mobileMenuData.extra_links"
                    v-for="(link, index) in mobileMenuData.extra_links"
                    :key="index"
                    class="o-mobile-menu__parent-link-wrapper">
                    <NuxtLink class="o-mobile-menu__parent-link" :to="link.link.url">
                        <span v-html="link.link.title"></span>
                    </NuxtLink>
                </li>
            </ul>
        </nav>
    </div>
</template>

<script setup>
    const props = defineProps({
        primary_navigation: {
            type: Array,
            required: false,
        },
        state: {
            type: Boolean,
            required: true,
        },
    });

    const router = useRouter();
    const mobileMenuData = ref(null);

    const getMobileMenuData = async () => {
        const { data } = await useFetch(`/api/wp/components/mobile-menu`);

        // Check if data is empty or undefined
        if (!data || !data.value || data.value.length === 0) {
            // console.log('No data found for the given route:', route.fullPath);

            throw createError({
                statusCode: 404,
                statusMessage: "Page Not Found",
            });
        }

        // console.log("Mobile Menu Data: ", data.value);

        mobileMenuData.value = data.value;
    };

    const toggleChildLinks = (event, firstLevel = false) => {
        const $target = event.currentTarget;

        if (firstLevel) {
            const $closest = $target.closest(".js-mobile-menu__parent-link-wrapper");

            // Collapse all other parent links
            document.querySelectorAll(".js-mobile-menu .is-active").forEach((element) => {
                if (element !== $target) {
                    element.classList.remove("is-active");
                }
            });

            if ($target.classList.contains("is-active")) {
                // Remove .is-active class from all children of $target
                $target.querySelectorAll("is-active").forEach((child) => {
                    child.classList.remove("is-active");
                });
            } else {
                $closest.querySelectorAll(".js-mobile-menu__child-links")[0].classList.toggle("is-active");
            }

            $target.classList.toggle("is-active");
        } else {
            const $closest = $target.closest(".js-mobile-menu__child-link-wrapper");

            $closest.querySelectorAll(".js-mobile-menu__child-links")[0].classList.toggle("is-active");

            $target.classList.toggle("is-active");
        }
    };

    // Collapse all sections on route change
    router.afterEach(() => {
        document.querySelectorAll(".js-mobile-menu .is-active").forEach((element) => {
            element.classList.remove("is-active");
        });
    });

    await getMobileMenuData();

    // console.log("Navigation: ", props.primary_navigation);
    // console.log("Mobile Menu Data: ", mobileMenuData.value);
</script>
