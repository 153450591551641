import { useDateFormat } from "@vueuse/core";

export default defineNuxtPlugin(() => {
    return {
        provide: {
            formatDate: (date: string, format: string = "DD/MM/YYYY") => {
                return useDateFormat(date, format);
            },
            formatCurrency: (number: number | string) => {
                number = Number(number);
        
                if (isNaN(number)) {
                    number = 0;
                }
                
                return number.toLocaleString("en-GB", {
                    style: "currency",
                    currency: "GBP",
                    minimumFractionDigits: 2,
                });
            }
        },
    };
});
