<template>
    <GlobalMobileMenu :primary_navigation="headerData?.navigation" :state="mobileMenuState" />

    <div
        class="o-header__container"
        @mouseenter="handleMouseState('header', true)"
        @mouseleave="handleMouseState('header', false)">
        <header class="o-header">
            <div class="grid-container">
                <div class="o-header__wrapper">
                    <div class="grid-container">
                        <div class="grid-x grid-margin-x align-justify align-middle">
                            <div class="cell shrink">
                                <NuxtLink v-if="headerData?.logo" class="o-header__logo" to="/">
                                    <img
                                        width="135" height="33"
                                        :src="headerData?.logo"
                                        alt="Holgates Holiday Parks logo"
                                        title="Holgates Holiday Parks"
                                         />
                                </NuxtLink>
                            </div>

                            <div class="cell auto">
                                <div class="o-header__items">
                                    <div v-if="headerData?.navigation" class="o-header__nav">
                                        <GlobalNavigation
                                            :navigation="headerData?.navigation"
                                            :megamenu_status="megamenuState"
                                            :megamenu_pane="currentPane"
                                            @toggle-megamenu="toggleMegamenu"
                                            @close-megamenu="closeMegamenu" />
                                    </div>

                                    <NuxtLink
                                        class="o-header__account"
                                        to="/account/"
                                        title="Visit your account"
                                        @mouseover="toggleMegamenu('close')">
                                        <i class="fa-light fa-user-group"></i>
                                    </NuxtLink>

                                    <NuxtLink
                                        v-if="headerData?.company_telephone"
                                        class="o-header__telephone"
                                        :to="`tel:${headerData?.company_telephone}`"
                                        @mouseover="toggleMegamenu('close')">
                                        <i class="fa-solid fa-phone"></i>
                                        <span v-html="headerData?.company_telephone"></span>
                                    </NuxtLink>

                                    <button
                                        class="o-header__mobile-nav-toggle"
                                        :class="[{ 'is-active': mobileMenuState }]"
                                        type="button"
                                        @click="toggleMobileMenuState"
                                        aria-label="Toggle mobile menu">
                                        <i class="fa-solid fa-xmark"></i>
                                        <i class="fa-solid fa-bars"></i>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                    <GlobalMegamenu
                        :primary_navigation="headerData?.navigation"
                        :current_pane="currentPane"
                        :megamenu_state="megamenuState"
                        @mouseenter="handleMouseState('megamenu', true)"
                        @mouseleave="handleMouseState('megamenu', false)"
                        @close-megamenu="closeMegamenu" />
                </div>
            </div>
        </header>
    </div>
</template>

<script setup>
    const router = useRouter();
    const headerData = ref(null);
    const currentPane = ref(null);
    const megamenuState = ref(false);
    const isMouseOverHeader = ref(false);
    const isMouseOverMegamenu = ref(false);
    const mobileMenuState = ref(false);

    const getHeaderData = async () => {
        const { data } = await useFetch(`/api/wp/components/header`);

        // Check if data is empty or undefined
        if (!data || !data.value || data.value.length === 0) {
            // console.log('No data found for the given route:', route.fullPath);

            throw createError({
                statusCode: 404,
                statusMessage: "Page Not Found",
            });
        }

        // console.log("Page Data: ", data.value);

        headerData.value = data.value;
    };

    await getHeaderData();

    const resetMegamenu = (megamenu) => {
        const panes = megamenu[0].querySelectorAll(":scope .js-megamenu__pane-links-wrapper");

        // Loop through all instances of $megamenuPane and set height to auto
        panes.forEach((pane) => {
            pane.style.height = "auto";
        });

        // Remove 'is-active' and 'is-visible' classes from all child elements of .js-megamenu
        megamenu[0].querySelectorAll(":scope.is-active, :scope .is-visible").forEach((el) => {
            el.classList.remove("is-active", "is-visible");
        });
    };

    const toggleMegamenu = (state, item, hasChildren) => {
        currentPane.value = item;

        if (state === "open" && hasChildren) {
            megamenuState.value = true;
        } else {
            megamenuState.value = false;
            currentPane.value = null;

            resetMegamenu(document.querySelectorAll(".js-megamenu"));
        }
    };

    const handleMouseState = (target, state) => {
        if (target === "header") {
            isMouseOverHeader.value = state;
        } else if (target === "megamenu") {
            isMouseOverMegamenu.value = state;
        }

        if (!state) checkCloseMegamenu(); // Only check close when mouse leaves
    };

    // Close megamenu if the mouse is not over either the header or the megamenu
    const checkCloseMegamenu = () => {
        if (!isMouseOverHeader.value && !isMouseOverMegamenu.value) {
            closeMegamenu();
        }
    };

    const closeMegamenu = () => {
        megamenuState.value = false;
        currentPane.value = null;

        resetMegamenu(document.querySelectorAll(".js-megamenu"));
    };

    const toggleMobileMenuState = () => {
        if (mobileMenuState.value == false) {
            mobileMenuState.value = true;
        } else {
            mobileMenuState.value = false;
        }
    };

    router.afterEach(() => {
        mobileMenuState.value = false;
    });
</script>
