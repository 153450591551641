<template>
    <div class="o-megamenu js-megamenu" :class="[{ 'is-active': megamenu_state }]">
        <div
            v-for="(pane, index) in megamenuPanes"
            :key="index"
            class="o-megamenu__pane"
            :class="[{ 'is-active': pane.parent_link.title === current_pane }]">
            <div class="grid-container">
                <div class="grid-x grid-margin-x">
                    <div class="small-12 cell">
                        <div class="o-megamenu__pane-content-wrapper">
                            <div class="o-megamenu__pane-links-wrapper js-megamenu__pane-links-wrapper">
                                <ul class="o-megamenu__pane-links">
                                    <li
                                        v-for="(section, index) in pane.child_link_sections"
                                        :key="index"
                                        @mouseenter="toggleSubmenu($event)"
                                        @mouseleave="hideSubmenu($event)">
                                        <div class="o-megamenu__section-parent">
                                            <NuxtLink
                                                class="o-megamenu__section-toggle"
                                                :to="section.section_link.url"
                                                @click="$emit('closeMegamenu')">
                                                <span v-html="section.section_title"></span>
                                            </NuxtLink>

                                            <div v-if="section.child_links" class="o-megamenu__toggle-arrow"></div>
                                        </div>

                                        <ul v-if="section.child_links">
                                            <li
                                                v-for="(secondary_link, index) in section.child_links"
                                                :key="index"
                                                @mouseenter="toggleSubmenu($event)"
                                                @mouseleave="hideSubmenu($event)">
                                                <div
                                                    v-if="secondary_link.child_links"
                                                    class="o-megamenu__section-parent">
                                                    <NuxtLink
                                                        class="o-megamenu__section-toggle"
                                                        :to="secondary_link.child_link.url"
                                                        @click="$emit('closeMegamenu')">
                                                        <span v-html="secondary_link.child_link.title"></span>
                                                    </NuxtLink>

                                                    <div class="o-megamenu__toggle-arrow"></div>
                                                </div>

                                                <NuxtLink
                                                    v-else
                                                    class="o-megamenu__link"
                                                    :to="secondary_link.child_link.url"
                                                    @click="$emit('closeMegamenu')">
                                                    <span v-html="secondary_link.child_link.title"></span>
                                                </NuxtLink>

                                                <ul v-if="secondary_link.child_links">
                                                    <li
                                                        v-for="(tertiary_link, index) in secondary_link.child_links"
                                                        :key="index">
                                                        <NuxtLink
                                                            class="o-megamenu__link"
                                                            :to="tertiary_link.child_link.url"
                                                            @click="$emit('closeMegamenu')">
                                                            <span v-html="tertiary_link.child_link.title"></span>
                                                        </NuxtLink>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </li>
                                </ul>
                            </div>

                            <template v-if="pane.featured_pod.enable_featured_pod">
                                <NuxtLink
                                    v-if="pane.featured_pod.featured_pod_link"
                                    class="o-megamenu__featured-pod"
                                    :to="pane.featured_pod.featured_pod_link.url"
                                    @click="$emit('closeMegamenu')">
                                    <div
                                        class="o-megamenu__featured-pod-media"
                                        v-if="pane?.featured_pod?.featured_pod_image?.attachments?.['large']"
                                        v-html="pane.featured_pod.featured_pod_image.attachments['large']"></div>

                                    <div class="o-megamenu__featured-pod-content-wrapper">
                                        <div
                                            v-if="pane.featured_pod.featured_pod_title"
                                            class="o-megamenu__featured-pod-title"
                                            v-html="pane.featured_pod.featured_pod_title"></div>

                                        <div
                                            v-if="pane.featured_pod.featured_pod_content"
                                            class="o-megamenu__featured-pod-content s-wp-cms"
                                            v-html="pane.featured_pod.featured_pod_content"></div>

                                        <div
                                            class="o-megamenu__featured-pod-link"
                                            v-html="pane.featured_pod.featured_pod_link.title"></div>
                                    </div>
                                </NuxtLink>

                                <div
                                    v-else
                                    class="o-megamenu__featured-pod"
                                    :to="pane.featured_pod.featured_pod_link.url">
                                    <div
                                        class="o-megamenu__featured-pod-media"
                                        v-html="pane.featured_pod.featured_pod_image.attachments['large']"></div>

                                    <div class="o-megamenu__featured-pod-content-wrapper">
                                        <div
                                            v-if="pane.featured_pod.featured_pod_title"
                                            class="o-megamenu__featured-pod-title"
                                            v-html="pane.featured_pod.featured_pod_title"></div>

                                        <div
                                            v-if="pane.featured_pod.featured_pod_content"
                                            class="o-megamenu__featured-pod-content s-wp-cms"
                                            v-html="pane.featured_pod.featured_pod_content"></div>
                                    </div>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
    defineEmits(["closeMegamenu"]);

    const props = defineProps({
        current_pane: {
            type: String,
            required: false,
        },
        megamenu_state: {
            type: Boolean,
            required: true,
        },
        primary_navigation: {
            type: Array,
            required: false,
        },
    });

    const megamenuPanes = ref([]);

    const updatePaneWrapperHeight = ($wrapper) => {
        if (!$wrapper) return;

        const leftColumn = $wrapper.querySelector(".o-megamenu__pane-links");
        const leftColumnHeight = leftColumn ? leftColumn.scrollHeight : 0;
        const $visibleLists = $wrapper.querySelectorAll("ul.is-active");

        if ($visibleLists.length === 0) {
            // If no submenus are active, collapse the wrapper
            $wrapper.style.height = "auto";
            return;
        }

        let maxHeight = 0;

        // Loop over each active submenu
        $visibleLists.forEach(($ul) => {
            // Temporarily set to auto
            $ul.style.height = "auto";

            // Compare the submenu’s natural height to the primary column’s height
            const submenuHeight = $ul.scrollHeight;
            const forcedHeight = Math.max(submenuHeight, leftColumnHeight);

            // Set the submenu's height to the tallest element
            $ul.style.height = forcedHeight + "px";

            // Track the tallest so we can set the wrapper height
            if (forcedHeight > maxHeight) {
                maxHeight = forcedHeight;
            }
        });

        // Set to enough height to accommodate the largest submenu
        $wrapper.style.height = maxHeight + 20 + "px";
    };

    const toggleSubmenu = (event) => {
        const parent = event.currentTarget.closest("li");

        if (!parent) return;

        // If this is a top-level menu item, hide all sibling submenus immediately.
        if (parent.parentElement.classList.contains("o-megamenu__pane-links")) {
            Array.from(parent.parentElement.children).forEach((sibling) => {
                if (sibling !== parent) {
                    const submenu = sibling.querySelector(":scope > ul");

                    if (submenu) {
                        submenu.classList.remove("is-active", "is-visible");
                    }

                    // Also remove active class from the sibling's parent link.
                    const siblingLink = sibling.querySelector(".o-megamenu__section-toggle");

                    if (siblingLink) {
                        siblingLink.classList.remove("is-active");
                    }
                }
            });
        }
        // Show the current submenu.
        const submenu = parent.querySelector(":scope > ul");

        if (submenu) {
            submenu.classList.add("is-active", "is-visible");
        }

        // Add active class to the associated parent link.
        const parentLink = parent.querySelector(".o-megamenu__section-toggle");

        if (parentLink) {
            parentLink.classList.add("is-active");
        }

        // Update the wrapper height.
        if (event?.currentTarget) {
            updatePaneWrapperHeight(event.currentTarget.closest(".js-megamenu__pane-links-wrapper"));
        }
    };

    const hideSubmenu = (event) => {
        const parent = event.currentTarget.closest("li");

        if (!parent) return;

        // Delay hiding to allow the user to move into the submenu.
        setTimeout(() => {
            // If the parent li is not hovered (including its descendants), then hide the submenu.
            if (!parent.matches(":hover")) {
                const submenu = parent.querySelector(":scope > ul");

                if (submenu) {
                    submenu.classList.remove("is-active", "is-visible");
                }

                // Remove active class from the associated parent link.
                const parentLink = parent.querySelector(".o-megamenu__section-toggle");

                if (parentLink) {
                    parentLink.classList.remove("is-active");
                }
                // Update the wrapper height.
                if (event?.currentTarget) {
                    updatePaneWrapperHeight(event.currentTarget.closest(".js-megamenu__pane-links-wrapper"));
                }
            }
        }, 150);
    };

    const getMegamenuPanes = (navLinks) => {
        // Loop through each nav link and, if the child_link_sections array is not empty, add it to the megamenuPanes array
        for (let i = 0; i < navLinks.length; i++) {
            if (navLinks[i].child_link_sections.length > 0) {
                megamenuPanes.value.push(navLinks[i]);
            }
        }

        // console.log('Panes: ', megamenuPanes.value);
    };

    await getMegamenuPanes(props.primary_navigation);

    // console.log("Nav: ", props.primary_navigation);
    // console.log("Panes: ", megamenuPanes.value);
</script>
