export default defineNuxtRouteMiddleware(async (to, from) => {
    const user = useSupabaseUser();
  
    const protectedRoutes = [
      "/account/",
      "/account/details",
      "/account/holidays",
      "/account/holidays/payments/",
    ];

  
    if (!user.value && protectedRoutes.some((route) => to.path.startsWith(route))) {
      return navigateTo("/login", { replace: true });
    }
  });
  