<template>
    <div class="site-body">
        <GlobalHeader />

        <main class="page-404">
            <div class="page-404__header">
                <div class="grid-container">
                    <div class="grid-x grid-margin-x">
                        <div class="small-12 cell">
                            <h1 class="page-404__page-title" v-html="pageData.page_title"></h1>
                        </div>
                    </div>
                </div>
            </div>

            <div v-if="pageData.page_content" class="utl-clearfix">
                <div class="o-flexible-content">
                    <div class="c-wysiwyg">
                        <div class="grid-container">
                            <div class="grid-x grid-margin-x">
                                <div class="small-12 cell">
                                    <div class="c-wysiwyg__content s-wp-cms" v-html="pageData.page_content"></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>

        <GlobalFooter />
    </div>
</template>

<script setup>
    const pageData = ref(null);

    const getPageData = async () => {
        const { data } = await useFetch(`/api/wp/error`);

        // Check if data is empty or undefined
        if (!data || !data.value || data.value.length === 0) {
            // console.log('No data found for the given route:', route.fullPath);

            throw createError({
                statusCode: 404,
                statusMessage: "Page Not Found",
            });
        }

        // console.log("Page Data: ", data.value);

        pageData.value = data.value;
    };

    await getPageData();

    // console.log("Page Data: ", pageData.value);
</script>
